<template>
  <div>
    <DiscountCompanyModal v-if="!isEndUser" />
    <CompanyDiscounts />
    <div v-if="!isEndUser" class="flex">
      <b-button
          v-b-modal.discountCompanyModal
          class="my-2"
          variant="primary"
      >
        Шаблони компанії
      </b-button>
      <b-form-checkbox
          v-model="isUserNotificationActive"
          size="md"
          name="check-button"
          switch
          @change="handleNotificationsForUsers($event)"
      >
        <b>Надсилати повідомлення про зміну шаблону знижок користувачу</b>
      </b-form-checkbox>
    </div>
    <AvailableDiscounts />
  </div>
</template>

<script>
import { BFormCheckbox, BButton } from "bootstrap-vue";
import AvailableDiscounts from "@/views/apps/discounts/components/AvailableDiscounts.vue";
import CompanyDiscounts from "@/views/apps/discounts/components/CompanyDiscounts.vue";
import DiscountCompanyModal from "@/views/apps/discounts/DiscountCompanyModal.vue";

import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";

export default {
components: {
  BFormCheckbox,
  BButton,
  AvailableDiscounts,
  CompanyDiscounts,
  DiscountCompanyModal,
},

  setup() {
    const { isUserNotificationActive, handleNotificationsForUsers, isEndUser } = useBusinessSettings();

    return { isUserNotificationActive, handleNotificationsForUsers, isEndUser };
  },

};
</script>