<template>
  <b-modal
      id="discountCompanyModal"
      title="Шаблони компанії"
      hide-footer
      no-close-on-backdrop
  >
    <b-list-group title="Активні шаблони">
      <b-list-group-item
          v-for="template in discountsDefault"
          :key="template.templateId"
          class="d-flex justify-content-between align-items-center"
      >
        <span>{{ getDiscountTemplatesTitle(template.templateId) }}</span>
        <span>{{ getAccountsTypesTitle(template.typeAccountId) }}</span>
        <b-button
            @click="removeDiscountTemplate(template.typeAccountId)"
            variant="danger"
            size="sm"
        >
          Прибрати знижку
        </b-button>
      </b-list-group-item>
    </b-list-group>
    <AddDiscountForm :default-params="{targetType: 1}" />
  </b-modal>
</template>

<script>

import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { BListGroup, BListGroupItem, BButton } from "bootstrap-vue";
import AddDiscountForm from "@/views/apps/discounts/AddDiscountForm.vue";
import { useDiscountsTemplateChange } from "@/composition/discounts/useDiscountsTemplateChange";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";

export default {
  name: "DiscountCompanyModal",

  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    AddDiscountForm
  },

  setup() {
    const { discountsDefault } = useBusinessSettings();
    const { getAccountsTypesTitle } = useAccountsTypes();
    const { changeDiscountTemplate } = useDiscountsTemplateChange();

    const { getDiscountTemplatesTitle } = useDiscountTemplatesFetch();

    function removeDiscountTemplate(typeAccount) {
      let params = {
        targetType: 1,
        typeAccount: typeAccount,
        templateId: 0,
      }
      changeDiscountTemplate(params);
    }

    return {
      discountsDefault,

      getDiscountTemplatesTitle,
      getAccountsTypesTitle,
      removeDiscountTemplate,
    };
  },
};
</script>