<template>
  <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
  >
    <b-form
        @submit.prevent="handleSubmit(addTemplate)"
        @reset.prevent="resetForm"
    >

      <validation-provider
          #default="validationContext"
          name="templateSelect"
          :rules="{required: true}"
      >
        <b-form-group
            id="templateSelect"
            label="Шаблон"
            label-for="templateSelect"
            :state="getValidationState(validationContext)"
        >
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="selectedTemplate"
              label="title"
              :options="discountTemplates"
              :reduce="val => val.id"
              name="templateSelect"
              placeholder="Оберіть шаблон"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="excludedBundleIds"
          :rules="{required: true}"
      >
        <b-form-group
            v-if="allowedAccountTypes"
            id="accountSelect"
            label="Аккаунт"
            label-for="accountSelect"
            :state="getValidationState(validationContext)"
        >
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="selectedAccountType"
              label="translated"
              :options="allowedAccountTypes"
              :reduce="val => val.value"
              name="accountSelect"
              placeholder="Оберіть аккаунт"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-button
          @click.prevent="handleSubmit(addTemplate)"
          variant="primary"
      >
        Додати шаблон
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>

import { BForm, BFormGroup, BButton, BFormInvalidFeedback } from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { required } from "@core/utils/validations/validations";
import vSelect from "vue-select";
import {onBeforeMount, ref} from "@vue/composition-api";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { useDiscountsTemplateChange } from "@/composition/discounts/useDiscountsTemplateChange";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  name: "AddDiscountForm",
  components: {
    BForm,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    defaultParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const selectedTemplate = ref(null);
    const selectedAccountType = ref(null);
    const { defaultParams } = props;

    const { getAccountsTypesTitle, allowedAccountTypes } = useAccountsTypes();
    const { changeDiscountTemplate } = useDiscountsTemplateChange();
    const { fetchDiscountTemplates, discountTemplates } = useDiscountTemplatesFetch();

    const resetTemplateParams = () => {
      selectedTemplate.value = null;
      selectedAccountType.value = null;
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTemplateParams);

    async function addTemplate() {
      let params = {
        typeAccount: selectedAccountType.value,
        templateId: selectedTemplate.value,
        ...defaultParams,
      }
      await changeDiscountTemplate(params);
      resetForm();
    }

    onBeforeMount(() => {
      fetchDiscountTemplates();
    })

    return {
      selectedTemplate,
      discountTemplates,
      selectedAccountType,
      allowedAccountTypes,
      getAccountsTypesTitle,
      required,

      refFormObserver,
      getValidationState,
      resetForm,
      addTemplate,
    };
  }
}
</script>