<template>
  <b-list-group class="my-2">
    <h4>Активні шаблони:</h4>
    <b-skeleton-wrapper :loading="discountTemplatesLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <b-list-group-item
          v-for="template in filteredDiscountsCurrent"
          :key="template.templateId"
          class="d-flex align-items-center flex-"
          :to="{ name: 'discount', params: { id: template.templateId } }"

      >
        <span>
          <span class="mr-1">{{ getDiscountTemplatesTitle(template.templateId) }}</span>
        -
          <span class="mx-1">{{ getAccountsTypesTitle(template.typeAccountId) }}</span>
        </span>
      </b-list-group-item>
    </b-skeleton-wrapper>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem, BSkeletonWrapper, BSkeleton, BCard } from 'bootstrap-vue';

import { useBusinessSettings } from '@/composition/businessSettings/useBusinessSettings';
import { useAccountsTypes } from '@/composition/formOptions/useAccountsTypes';
import { useDiscountTemplatesFetch } from '@/composition/discounts/useDiscountTemplatesFetch';

export default {
  name: 'CompanyDiscounts',

  components: {
    BListGroup,
    BListGroupItem,
    BSkeletonWrapper,
    BSkeleton,
    BCard,
  },
  computed: {
    filteredDiscountsCurrent() {
      return this.discountsCurrent.filter(template => this.accountTypes.includes(template.typeAccountId));
    }
  },
  setup() {
    const { discountsCurrent, accountTypes } = useBusinessSettings();
    const { getAccountsTypesTitle } = useAccountsTypes();
    const { getDiscountTemplatesTitle, discountTemplatesLoading } = useDiscountTemplatesFetch();

    return {
      discountsCurrent,
      accountTypes,
      getAccountsTypesTitle,
      getDiscountTemplatesTitle,
      discountTemplatesLoading,
    };
  }
};
</script>

<style scoped>

</style>
